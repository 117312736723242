<template>
    <div class="container">
        <div class="top">
            <div v-html="faq.answer"></div>
        </div>
        <div class="type">
            <div class="title">相关问题</div>
            <div class="content">
                <div class="item" v-for="(item) in faq.relevantFaq" :key="item.faqId" @click="viewDetail(item.faqId)">
                    <div class="txt">{{item.problem}}</div>
                    <div>
                        <van-icon name="arrow"/>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
    import {getFaqAnswer} from '@/api/api'

    export default {
        data() {
            return {
                faq: {
                    answer: '',
                    relevantFaq: []
                }
            }
        },
        created() {
            let id = this.$route.query.id
            if (!id) {
                this.$failToast('参数错误')
                return
            }
            getFaqAnswer({
                id: id
            }).then(({data})=>{
                this.faq = data
            })
        },
        methods: {
            viewDetail(id) {
                this.$router.push('/problem/detail?id='+id)
                window.scrollTo(0,0);
            }

        }
    }
</script>



<style scoped lang="scss">

    .container{
        padding: 20px;
        font-size: 18px;

        .type {
            background-color: #ffffff;
            margin-bottom: 20px;
            margin-top: 20px;
        }

        .title {
            font-weight: bold;
            font-size: 18px;
            padding: 20px 10px;
        }

        .item {
            display: flex;
            justify-content: space-between;
            font-size: 16px;
            padding: 10px;
            border-top: 2px solid rgb(247, 247, 249);
        }
        .item:active{
            opacity: 0.7;
        }
    }

</style>
